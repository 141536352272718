import {EventEmitter, inject, Injectable, signal} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {API_URL, FILE_UPLOAD_URL} from '../../../constants/api.constant';
import {
  IPost,
  IPostComment,
  IPostCommentRequestBody,
  IPostRequestBody,
  IPostFileUpload, IPostResponseBody, IPostCommentResponseBody
} from '../../../interfaces/i-post';
import { IGenericResponse } from '../../../interfaces/i-api-responses';

@Injectable({
  providedIn: 'root'
})
export class MyPostsService {
  openPostDialog = signal(false);
  openCommentDialog = signal(false);
  isRecordingScreenCapture = signal(false);
  newComment = signal<IPostCommentResponseBody | null>(null);

  #newCommentEvent: EventEmitter<IPostCommentResponseBody> = new EventEmitter();
  #mediaFile: Blob | undefined;
  #http = inject(HttpClient);

  constructor() {
  }

  get mediaFile() {
    return this.#mediaFile!;
  }

  set mediaFile(media: Blob | undefined) {
    this.#mediaFile = media;
  }

  emitNewCommentEvent(newComment: IPostCommentResponseBody) {
    this.#newCommentEvent.emit(newComment);
  }

  getNewCommentEvent() {
    return this.#newCommentEvent;
  }

  addPost(requestBody: Partial<IPostRequestBody>) {
    const url = `${API_URL}/savePost`;

    return this.#http.post<Partial<{postId: number, result: boolean}>>(url, requestBody);
  }

  addComment(requestBody: IPostCommentRequestBody) {
    const url = `${API_URL}/saveComment`;

    return this.#http.post<IPostCommentResponseBody>(url, requestBody);
  }

  getPosts(requestBody: { userId: number }) {
    const url = `${API_URL}/getPosts`;

    return this.#http.post<IPostResponseBody[]>(url, requestBody);
  }

  getPost(postId: number) {
    const params = {params: new HttpParams().set('id', postId)};
    const url = `${API_URL}/getPost`;

    return this.#http.get<Partial<IPostResponseBody>>(url, params);
  }

  fileUpload(requestBody: any) {
    const url = `${FILE_UPLOAD_URL}/fileUpload`;
    return this.#http.post(url, requestBody);
  }
}
