import { inject, Injectable, signal } from '@angular/core';
import { IUserProfile, IUserProfileResponse } from '../../../interfaces/i-user-profile';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_URL } from '../../../constants/api.constant';
import { InterestType } from '../../../enums/api.enum';
import { IInterest } from '../../../interfaces/i-interest';
import { ProfileType } from '../../../types/api';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  userProfile = signal<IUserProfile>({id: 0});
  accountProfile = signal<ProfileType | null>(null);
  accountAvatar = signal<string>('');
  #allInterests: IInterest[] = [];

  #http = inject(HttpClient);

  get allInterests(): IInterest[] {
    return this.#allInterests;
  }

  set allInterests(interests: IInterest[]) {
    this.#allInterests = interests;
  }

  /**
   * Register the user's profile data.
   */
  registration(requestBody: { email: string, businessUnitId: number, firstName: string, lastName: string }) {
    const url = `${API_URL}/registration`;
    return this.#http.post<IUserProfileResponse>(url, requestBody);
  }

  /**
   * Saves the user's profile data.
   */
  saveProfile(requestBody: { userId: number, businessUnitId: number, firstName: string, lastName: string, email: string }) {
    const url = `${API_URL}/saveProfile`;
    return this.#http.post<{result: boolean}>(url, requestBody);
  }

  /**
   * Get's a list of all the interests available.
   */
  getInterests(interestType: InterestType | string) {
    const params = interestType ? { params: new HttpParams().set('type', interestType) } : {};
    const url = `${API_URL}/getInterests`;
    return this.#http.get<IInterest[]>(url, params);
  }

  /**
   * Gets the user's interests.
   */
  getUsersInterests() {
    const url = `${API_URL}/getUserInterests?id=${this.userProfile().id}`;
    return this.#http.get<IInterest[]>(url);
  }

  /**
   * Saves a list of the user's interests
   */
  saveInterests(requestBody: {userId: number, interestId: number}[]) {
    const url = `${API_URL}/saveInterests`;
    return this.#http.post<{result: boolean;}>(url, requestBody);
  }

  /**
   * Gets the account information from MS Graph
   */
  getAccount() {
    return this.#http.get<Partial<ProfileType>>(environment.apiConfig.uri);
  }

  getProfile(requestBody: {email: string}) {
    const url = `${API_URL}/getProfile`;
    return this.#http.post<IUserProfileResponse>(url, requestBody);
  }

  /**
   * Sets the user's avatar.
   */
  setAvatar(): void {
    if (this.accountProfile()?.displayName) {
      const name = this.accountProfile()?.displayName;
      const nameArray = name?.split(',');
      this.accountAvatar.set(`${nameArray![1].trim().charAt(0).toUpperCase()}${nameArray![0].trim().charAt(0).toUpperCase()}`);
    }
  }
}
