import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { IAlert } from './i-alert';
import { AlertEnum } from './alert.enum';
import { AlertComponent } from './alert.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  #snackBar = inject(MatSnackBar);

  showAlert(alert: IAlert) {
    const config = this.#getSnackBarConfig(alert);

    this.#snackBar.openFromComponent(AlertComponent, config);
  }

  #getSnackBarConfig(data: IAlert): MatSnackBarConfig {
    let panelClass = '';
    switch (data.type) {
      case AlertEnum.warn:
        panelClass = 'snackbar-warn';
        break;
      case AlertEnum.error:
        panelClass = 'snackbar-error';
        break;
      case AlertEnum.success:
        panelClass = 'snackbar-success';
        break
      default:
        panelClass = 'snackbar-info';
        break;
    }

    return {
      data,
      duration: 5 * 1000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      politeness: 'polite',
      panelClass
    }
  }
}
